.container {
    padding-top: 2px;
    padding-bottom: 2px;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0.017em;
    background: #2c3c93;
}

.sub-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.header-left {
    display: flex;
    align-items: center;
    color: #fff;
}

.header-left:link {
    color: #fff;
}

.header-right {
    display: flex;
}

.header-menu {
    display: flex;
}

.header-menu-item {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 0.8rem;
    color: #fff;
}

.header-menu-item:link {
    color: #fff;
}




.header-container {
    display: flex;
    background-color: #fff;
    padding: 2.7rem;
    justify-content: space-between;
    border-bottom: 3px solid #2c3c93;
}

.sticky-header-right {
    display: flex;
}

.sticky-header-items {
    display: flex;
}

.sticky-header-cart-badge {
    position: absolute;
    top: 5px;
    right: 5px;

}

.sticky-header-item-fast-order {
    display: flex;
}

.search-input-container {
    margin-right: 20px;
    min-width: 21rem;
}

.search-input {
    padding: 10px;
    width: 100%;
}

@media (min-width: 992px) {
    .sub-container {
        padding-left: 15px;
        padding-right: 15px;
    }
}


.navigation {
    position: relative;
    display: inline-block;
}

.navigation-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    
    border-radius: 4px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.navigation a {
    color: black;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: block;
}

.navigation a:hover {
    background-color: #ddd;
    border-radius: 4px;
}

.navigation-content a {
    min-width: 160px;
}

.navigation-content a:hover {
    background-color: #DA1212;
    color: #fff;
}

.navigation:hover .navigation-content {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(6, auto);
}